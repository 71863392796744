import { useState, useEffect } from "react";
import { darkLogo } from "../../resources/options/images";
import * as baseApi from "../../api/base";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaBehance,
  FaAngleRight,
  FaAngleDoubleRight,
  FaLinkedin,
  FaDribbble,
} from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Footer = ({ styleSelect, interestedTrue }) => {
  let Interested = null;
  const { pathname } = useLocation();
  const [fieldList, SetFieldList] = useState({
    email: "",
  });
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (pathname === "/portfolio" || pathname === "") {
      interestedTrue = false;
    }
  }, [pathname]);
  if (interestedTrue) {
    Interested = (
      <div className="col-md-10 offset-md-1">
        <div className="join_us_mid">
          <h3>Interested to Join Us, We Seeking</h3>
          <a href="/career" className="btn">
            Join Our Team
          </a>
        </div>
        {/* join_us_mid */}
      </div>
    );
  }
  const clearFormData = () => {
    SetFieldList({
      email: "",
    });
  };
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      var formData = new FormData();
      for (const prop in fieldList) {
        formData.append(prop, fieldList[prop]);
      }
      const response = await baseApi.subscribeus(formData);
      const data = response.data;
      if (data.success === true) {
        alert("Subscribe Successfuly!");
        clearFormData();
      } else {
        alert("Somthing went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <>
      {path === "/connect" ? (
        <></>
      ) : (
        <>
          {/* footer section start*/}
          <section className={`footer_x_widger ${styleSelect}`}>
            <div className="container">
              <div className="row">
                {Interested}
                <div className="w-100"></div>
                <div className="col-md-6">
                  <div className="widget widget_info">
                    <div className="footer_logo_container">
                      <div className="footer_logo">
                        <img src={darkLogo} alt="footer logo" />
                      </div>
                    </div>
                    <p>
                      Smart and Creative digital Information technology agency
                      from surat
                    </p>
                    <div className="newsletter">
                      <h3>Subscribe Here!</h3>
                      <form onSubmit={handleSubmit}>
                        <input
                          type="email"
                          required
                          placeholder="Enter your Email"
                          value={fieldList.email}
                          onChange={(e) => {
                            SetFieldList({
                              ...fieldList,
                              email: e.target.value,
                            });
                          }}
                        />
                        <button className="btn" type="submit">
                          <FaAngleRight />
                        </button>
                      </form>
                    </div>
                  </div>
                  {/* widget_info */}
                </div>
                <div className="col-md-3">
                  <div className="widget widget_links">
                    <h3>Useful Links</h3>
                    <ul>
                      <li>
                        <a href="/about">
                          <FaAngleDoubleRight /> About Company
                        </a>
                      </li>
                      <li>
                        <a href="/career">
                          <FaAngleDoubleRight /> Career
                        </a>
                      </li>
                      {/* <li>
                        <a href="https://blog.sct.technology/">
                          <FaAngleDoubleRight /> Blog
                        </a>
                      </li> */}
                      <li>
                        <a href="/contact">
                          <FaAngleDoubleRight /> Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* widget_links */}
                </div>
                <div className="col-md-3">
                  <div className="widget widget_address">
                    <h3>Address</h3>
                    <p>
                      5<sup>th</sup> Floor, Office No. 511, Kyros Business Center,
                      Near navjivan hotel, Jakatnaka,
                      Surat, Gujarat, 395006
                    </p>
                    <div className="con_info">
                      <h4>Contact Us</h4>
                      <p>
                        <a href="tel:+91-9427894598">+91 9427894598</a>
                      </p>
                      <p>
                        <a href="mailto:contactus@sct.technology">
                        contactus@sct.technology
                        </a>
                      </p>
                    </div>
                  </div>
                  {/* widget_address */}
                </div>
              </div>
            </div>
            {/* container */}
          </section>
          {/* footer widget */}

          {/* footer */}
          <footer className="footers">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="copyright_text">
                    <p>
                      Copyright © {new Date().getFullYear()} SCT Technology, all rights reserved.
                    </p>
                  </div>
                  {/* copyright_text */}
                </div>
                <div className="col-md-6">
                  <div className="footer_social">
                    <ul>
                      <li>
                        <a href="#">
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaDribbble />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaTwitter />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaLinkedin />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaBehance />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <FaInstagram />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* footer_social */}
                </div>
              </div>
            </div>
            {/* container*/}
          </footer>
          {/* footer section end*/}
        </>
      )}
    </>
  );
};

export default Footer;
