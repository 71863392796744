import Breadcrumb from "../components/common/Breadcrumb";
import PageHelmet from "../components/common/Helmet";
import Footer from "../components/footer/Footer";
import HeaderMain from "../components/header/HeaderMain";
import CareerAll from "../components/home/career/CareerAll";
import CareerBlock from "../components/home/career/Career";
const Career = () => {
  return (
    <>
      {/* Page title area start  */}
      <PageHelmet pageTitle="Career" />
      {/* Page title area end  */}

      {/* Header area start  */}
      {/* <HeaderMain logo="dark" styleSelect="dark_color" /> */}
      {/* Header area end  */}

      {/* Breadcrumb area start  */}
      <Breadcrumb pathName="Career" />
      {/* Breadcrumb area end  */}

      <CareerBlock />

      {/* CallToAction area start  */}
      <CareerAll />
      {/* CallToAction area end  */}

      {/* Footer area start  */}
      {/* <Footer styleSelect="home2" interestedTrue /> */}
      {/* Footer area end  */}
    </>
  );
};

export default Career;
