import Footer from "../components/footer/Footer";
import HeaderMain from "../components/header/HeaderMain";
import About from "../components/home/about/About";
import Feature from "../components/home/features/Feature";
import Service from "../components/home/services/Service";
import CaseStudie from "../components/home/casestudies/CaseStudie";
import PricingPlan from "../components/home/pricing/PricingPlan";
import Team from "../components/home/team/Team";
import LatestWork from "../components/home/latestwork/LatestWork";
import CallToAction from "../components/home/action/CallToAction";
import Testimonial from "../components/home/testimonials/Testimonial";
import Plan from "../components/home/plan/Plan";
import RecentBlog from "../components/home/blog/RecentBlog";
import Banner from "../components/home/banner/Banner";
import PageHelmet from "../components/common/Helmet";
import OurValues from "../components/home/ourvalues/OurValues";
import OurTechnologies from "../components/home/ourtechnologies";
const HomeOne = () => {
  return (
    <>
      {/* Page title area start  */}
      <PageHelmet pageTitle="Home" />
      {/* page title area end  */}

      {/* Header area start  */}
      {/* <HeaderMain logo="dark" styleSelect="dark_color" /> */}
      {/* Header area end  */}

      {/* Banner area start  */}
      <Banner />
      {/* Banner area end  */}

      {/* OurValues start */}
      <OurValues />
      {/* OurValues end */}

      {/* About area start  */}
      <About readMore />
      {/* About area end  */}

      {/* Feature area start  */}
      <Feature />
      {/* Feature area end  */}

      {/* Our TTech area start  */}
      <OurTechnologies />
      {/* Our Tech area end  */}

      {/* Service area start  */}
      <Service />
      {/* Service area end  */}

      {/* LatestWork area start  */}
      {/* <LatestWork /> */}
      {/* LatestWork area end  */}

      {/* CallToAction area start  */}
      <CallToAction />
      {/* CallToAction area end  */}

      {/* Testimonial area start  */}
      <Testimonial />
      {/* Testimonial area end  */}

      {/* Plan area start  */}
      <Plan />
      {/* Plan area end  */}

      {/* Recent Blog area start  */}
      {/* <RecentBlog /> */}
      {/* Recent Blog area end  */}

      {/* Footer area start  */}
      {/* <Footer styleSelect="home2" interestedTrue={true} /> */}
      {/* Footer area end  */}
    </>
  );
};

export default HomeOne;
