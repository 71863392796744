import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.props.pageTitle} | SCT Technology
          </title> 
          {/* <meta
            name="description"
            content="SCT Technology is a globally recognized IT company and also a family filled with talented Experts that help global brands, enterprises, mid-size businesses, or even startups with innovative solutions."
          ></meta> */}
          <meta
            property="og:description"
            content="SCT Technology excels in delivering top-tier development services for games, websites, mobile apps, e-commerce platforms, and on-demand applications. Our offerings are enriched with cutting-edge AI solutions including AI agents and voice bots, ensuring innovative and efficient digital solutions for businesses of all sizes."
          ></meta>
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
