import Breadcrumb from "../components/common/Breadcrumb";
import PageHelmet from "../components/common/Helmet";
import Footer from "../components/footer/Footer";
import HeaderMain from "../components/header/HeaderMain";
import { error404Image } from "../resources/options/images";

const Error404 = () => {
  return (
    <>
      {/* Page title area start  */}
      <PageHelmet pageTitle="404" />
      {/* Page title area end  */}

      {/* Header area start  */}
      {/* <HeaderMain logo="dark" styleSelect="dark_color" /> */}
      {/* Header area end  */}

      {/* Breadcrumb area start  */}
      <Breadcrumb pathName="404" />
      {/* Breadcrumb area end  */}

      {/* 404 main_page start */}
      <div className="error_page_main_con">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="inside">
                <img src={error404Image} alt="" />
                <h4>Something went wrong Page Can't Found</h4>
                <a href="/" className="btn">
                  Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* container */}
      </div>
      {/* 404 main_page end */}

      {/* Footer area start  */}
      {/* <Footer styleSelect="home2" interestedTrue /> */}
      {/* Footer area end  */}
    </>
  );
};

export default Error404;
