import django from "../../assets/img/ourtech/django.png";
import flutter from "../../assets/img/ourtech/flutter.png";
import javascript from "../../assets/img/ourtech/javascript.png";
import jquery from "../../assets/img/ourtech/jquery.png";
import mongodb from "../../assets/img/ourtech/mongodb.png";
import node from "../../assets/img/ourtech/node.png";
import php from "../../assets/img/ourtech/php-logo.svg";
import python from "../../assets/img/ourtech/python.png";
import reactJs from "../../assets/img/ourtech/react.png";
import react from "../../assets/img/ourtech/react-native-logo.png";
import vue from "../../assets/img/ourtech/vue-logo.svg";
import openAi from "../../assets/img/ourtech/openai.svg";
import AI from "../../assets/img/ourtech/artificial-intelligence.png";
import spring from "../../assets/img/ourtech/Springboot.png";
import java from "../../assets/img/ourtech/java.svg"

export const ourTech = [
  {
    id: 1,
    img: AI,
    alt: "image not found",
    title:"Artificial intelligence"

  },
  {
    id: 2,
    img: openAi,
    alt: "image not found",
    title:"Open Ai"
  },
  {
    id: 3,
    img: django,
    alt: "image not found",
    title:"django"
  },
  {
    id: 4,
    img: flutter,
    alt: "image not found",
    title:"Flutter"
  },
  {
    id: 5,
    img: javascript,
    alt: "image not found",
    title:"JavaScript"
  },
  {
    id: 6,
    img: java,
    alt: "image not found",
    title:"Java"
  },
  {
    id: 7,
    img: jquery,
    alt: "image not found",
    title:"Jquery"
  },
  {
    id: 8,
    img: mongodb,
    alt: "image not found",
    title:"MongoDB"
  },
  {
    id: 9,
    img: node,
    alt: "image not found",
    title:"Node"
  },
  {
    id: 10,
    img: php,
    alt: "image not found",
    title:"PHP"
  },
  {
    id: 11,
    img: python,
    alt: "image not found",
    title:"Python"
  },
  {
    id: 12,
    img: reactJs,
    alt: "image not found",
    title:"ReactJs"
  },
  {
    id: 13,
    img: react,
    alt: "image not found",
    title:"React"
  },
  {
    id: 14,
    img: spring,
    alt: "image not found",
    title:"Spring Boot"
  },
  {
    id: 15,
    img: vue,
    alt: "image not found",
    title:"VUE JS"
  },
];
