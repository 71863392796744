import { useState } from "react";
import SingleAllService from "../../../elements/service/SingleAllService";
import {
  service1bg,
  service2bg,
  service2bg_hv,
  service3bg,
  service3bg_hv,
  service4bg,
  service4bg_hv,
  // service_blockchain,
  service_web,
  service_software,
  service_SEO,
  service_app,
  service_UI_UX,
} from "../../../resources/options/images";
import Shape from "./partial/Shape";

const SerivceAll = () => {
  const [serviceList] = useState([
    {
      title: "AI Development",
      imgList: {
        img: service_app,
        bg: service3bg,
        hover: service3bg_hv,
      },
      description:
        "Our AI Development service provides cutting-edge solutions tailored to your business needs. Harnessing the latest advancements in artificial intelligence, we offer customized AI models, seamless integration, and continuous support. Elevate your operations, drive innovation, and achieve transformative results with our expert AI development team.",
      duration: 2000,
      alt: "AI Development",
      style: "bg3",
    },
    // {
    //   title: "Blockchain Development",
    //   imgList: {
    //     img: service_blockchain,
    //     bg: service1bg,
    //     hover: service3bg_hv,
    //   },
    //   description:
    //     "We are a completely bootstrapped blockchain-based software development company with 4 years of track record under our belt. I2I is an initiative to help blockchain and crypto start-ups create groundbreaking software solutions that impact the world for the better. We have a team of expert developers and tech maestros who have been in the blockchain app development services industry for many years.",
    //   duration: 1000,
    //   alt: "Blockchain Development",
    //   style: "bg1",
    // },
    {
      title: "Web Development",
      imgList: {
        img: service_web,
        bg: service4bg,
        hover: service4bg_hv,
      },
      description:
        "Our web applications team are experts in the field, providing superior web solutions. We create web applications that are tailored to your business needs and market requirements. The focus is on ease of use and on maintaining visitor experience.",
      duration: 4000,
      alt: "Web Development",
      style: "bg4",
    },
    {
      title: "UI/UX Design",
      imgList: {
        img: service_UI_UX,
        bg: service2bg,
        hover: service2bg_hv,
      },
      description:
        "Starting from concept, information architecture, visual identity, and UI/UX design, our team delivers dazzling experiences for maximum user engagement.",
      duration: 3000,
      alt: "UI/UX Design",
      style: "bg2",
    },
    {
      title: "Software Development",
      imgList: {
        img: service_software,
        bg: service1bg,
        hover: service3bg_hv,
      },
      description:
        "Create your own team of developers for your software development project on a short-term, long-term, or permanent basis with guaranteed project delivery at affordable prices.",
      duration: 1000,
      alt: "Software Development",
      style: "bg1",
    },

    // {
    //   title: "SEO Marketing",
    //   imgList: {
    //     img: service_SEO,
    //     bg: service4bg,
    //     hover: service4bg_hv,
    //   },
    //   description:
    //     "We offer the best SEO techniques that are developed by our dedicated SEO experts to ensure maximum leads for your business, organic web visibility, and traffic.",
    //   duration: 4000,
    //   alt: "SEO Marketing",
    // },
    // {
    //   title: "Virtual Reality Apps Development",
    //   imgList: {
    //     img: service_6,
    //     bg: service4bg,
    //     hover: service4bg_hv,
    //   },
    //   description:
    //     "Our VR app solutions are a perfect fit for industrial use cases, enterprises, and entertainment. We have proven expertise in delivering VR app development services to build captivating 3D environments and interactive VR apps for diverse industry verticals.",
    //   duration: 4000,
    //   alt: "Virtual Reality Apps Development",
    // },
    // {
    //   title: "AI - ML App Development",
    //   imgList: {
    //     img: service_7,
    //     bg: service4bg,
    //     hover: service4bg_hv,
    //   },
    //   description:
    //     "We build AI-ML solutions that will save you up to 30% on the cost of your overall business operations. We have expertise in working with tools, frameworks, and technologies like TensorFlow, Apache SystemML, Caffe, Apache Mahout, OpenNN, Torch, Neuroph, Mycroft AI, etc.",
    //   duration: 4000,
    //   alt: "AI - ML App Development",
    // },
    // {
    //   title: "IoT Solutions",
    //   imgList: {
    //     img: service_8,
    //     bg: service4bg,
    //     hover: service4bg_hv,
    //   },
    //   description:
    //     "Our IoT experts develop bespoke and innovative IoT solutions to simplify and automate complex business processes. Together, we can create amazing wireless experiences with modern IoT hardware to collect information . Hire the best IoT programmers from i2i infotech who help startups and enterprises build an IoT platform and connect it with other cloud, onsite applications, or multiple IoT platforms.",
    //   duration: 4000,
    //   alt: "IoT Solutions",
    // },
  ]);

  return (
    <>
      {/* service-page-inner */}
      <section className="service_page_inner" id="service_page_inner_id">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="title text-center">
                <h3>Services we're providing</h3>
                <p>
                  We provide a complete and accomplished bootstrap software
                  development service, with a track record of success spanning
                  four years. Our initiative is to help start-ups create
                  innovative software solutions that impact the world for the
                  better.
                </p>
              </div>
              {/* title */}
            </div>
            <div className="w-100"></div>

            {serviceList.map((item, index) => (
              <div className="col-md-6" key={index}>
                <SingleAllService item={item} />
              </div>
            ))}
          </div>
        </div>
        {/* container */}
        <Shape />
      </section>
      {/* service-page-inner*/}
    </>
  );
};

export default SerivceAll;
