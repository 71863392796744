import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

const OffCanvasSection = ({ darkLogo, mobileMenuToggle, mobileMenu }) => {
  var elements = document.querySelectorAll(".dropdown>div");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement
          .querySelector(".dropdown-menu")
          .classList.toggle("show");
        this.classList.toggle("open");
      };
    }
  }

  return (
    <>
      <div className="off_canvus_menu">
        <div className="off_canvus_menu_iner">
          <div className="off_canvus_menu_iner_logo">
            <a href="/" className="off_canvus_logo">
              <div style={{ width: "100px", height: "50px" }}>
                <img src={darkLogo} alt="" className="img-fluid" />
              </div>
            </a>
            <div
              className="popup-close-button close_icon"
              onClick={mobileMenuToggle}
            >
              <i>
                <FaTimes />
              </i>
            </div>
          </div>
          <div className="off_canvus_menu_iner_content">
            <nav className="navbar">
              <ul className="navbar-nav" onClick={mobileMenuToggle}>
                <li className="nav-item ">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/about" className="nav-link">
                    About Us
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link to="/services" className="nav-link">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/portfolio" className="nav-link">
                    Portfolio
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Case Studies
                  </div>

                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/case-studies" className="dropdown-item">
                        Case Studies
                      </Link>
                    </li>

                    <li>
                      <Link to="/case-details" className="dropdown-item">
                        Case Details
                      </Link>
                    </li>

                    <li>
                      <Link to="/teams" className="dropdown-item">
                        Teams
                      </Link>
                    </li>

                    <li>
                      <Link to="/404" className="dropdown-item">
                        404
                      </Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Blocks
                  </div>

                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/testimonials" className="dropdown-item">
                        Testimonials
                      </Link>
                    </li>
                    <li>
                      <Link to="/pricing-package" className="dropdown-item">
                        Pricing Package
                      </Link>
                    </li>

                    <li>
                      <Link to="/faqs" className="dropdown-item">
                        FAQ
                      </Link>
                    </li>

                    <li>
                      <Link to="/call-to-action" className="dropdown-item">
                        Call to Action
                      </Link>
                    </li>

                    <li>
                      <Link to="/latest-news" className="dropdown-item">
                        Latest News
                      </Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="nav-item">
                  <div className="nav-link">
                    <a href="https://blog.sct.technology/">Blog</a> */}
                {/* <Link to="/blog">
                      Blog
                    </Link> */}
                {/* </div>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Blogs
                  </div>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/blog" className="dropdown-item">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog-details" className="dropdown-item">
                        Blog Details
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item">
                  <Link to="/career" className="nav-link">
                    Career
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to="/email/" className="nav-link">
                    Login
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div
        onClick={mobileMenuToggle}
        className={`offcanvas_overlay ${mobileMenu ? "active_offcanvas_overlay" : ""
          }`}
      ></div>
    </>
  );
};

export default OffCanvasSection;
