import axios from "axios";
const baseURL =
  process.env.REACT_APP_SERVER_URL || "/server";
// process.env.REACT_APP_SERVER_URL || "https://www.sct.technology/server" || "http://localhost/i2iInfotech";
export { baseURL };
// console.warn("baseURL ==> ", baseURL);
const client = axios.create({
  baseURL,
});

client.interceptors.response.use(undefined, (error) => {
  if (error.response?.status === 401) {
    window.location.reload();
  }

  if (
    error.response?.status === 404 &&
    error.response?.data?.message ===
      "You are logged out because you logged in with some other device. Please login again."
  ) {
    window.location.reload();
  }

  error.message = error.response
    ? error.response.data.message
    : error.request
    ? error.message
    : "Something went wrong. Try again.";
  return Promise.reject(error);
});

export default client;
