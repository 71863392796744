const SingleService = ({ item }) => {
  return (
    <>
      {/* single_service_in start*/}
      <div
        className={`single_service_in ${item.style}`}
        data-aos="flip-down"
        data-aos-duration={item.duration}
      >
        <div className="icon">
          <img src={item.imgList.img} alt="" />
          <img src={item.imgList.bg} className="show" alt={`${item.alt} b`} />
          <img
            src={item.imgList.hover}
            className="hide"
            alt={`${item.alt} hv`}
          />
        </div>
        <h4>{item.title}</h4>
        <p>{item.description}</p>
      </div>
      {/* single_service_in end*/}
    </>
  );
};

export default SingleService;
