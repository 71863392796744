import Breadcrumb from "../components/common/Breadcrumb";
import PageHelmet from "../components/common/Helmet";
import Footer from "../components/footer/Footer";
import HeaderMain from "../components/header/HeaderMain";
import AboutSection from "../components/home/about/About";
import AboutUs from "../components/home/about/About-us";
import PricingPlan from "../components/home/pricing/PricingPlan";
import TeamTwo from "../components/home/team/TeamTwo";
import TestimonialTwo from "../components/home/testimonials/TestimonialTwo";

const About = () => {
  return (
    <>
      {/* Page title area start  */}
      <PageHelmet pageTitle="About Us" />
      {/* Page title area end  */}

      {/* Header area start  */}
      {/* <HeaderMain logo="dark" styleSelect="dark_color" /> */}
      {/* Header area end  */}

      {/* Breadcrumb area start  */}
      <Breadcrumb pathName="About Us" />
      {/* Breadcrumb area end  */}

      {/* About area start  */}
      <AboutSection />
      {/* About area end  */}

      {/* AboutUs area start  */}
      <AboutUs />
      {/* AboutUs area end  */}

      {/* Team area start  */}
      {/* <TeamTwo /> */}
      {/* Team area end  */}

      {/* Pricing Plan area start  */}
      {/* <PricingPlan styleSelect="home2" /> */}
      {/* Pricing Plan area end  */}

      {/* Testimonial area start  */}
      <TestimonialTwo />
      {/* Testimonial area end  */}

      {/* Footer area start  */}
      {/* <Footer styleSelect="home2" interestedTrue /> */}
      {/* Footer area end  */}
    </>
  );
};

export default About;
