/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from "react";
import GoogleMapReact from "google-map-react";
import { FaEnvelope, FaMapMarker, FaPhoneAlt } from "react-icons/fa";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ContactMain = () => {
  const [options] = useState({
    center: {
      lat: 39.95,
      lng: 40.33,
    },
    zoom: 11,
  });

  return (
    <>
      {/* contact page start*/}
      <div className="contact-address-map">
        <div style={{ height: "500px", width: "100%" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d903.266523737935!2d72.90580005810246!3d21.234353275348408!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be045c7a7914c3d%3A0x67681ccdc7531c10!2sKyros%20business%20centre!5e0!3m2!1sen!2sin!4v1719827696691!5m2!1sen!2sin"
            width="600"
            height="500"
            style={{ border: "0" }}
            allowFullScreen={true}
            loading="lazy"
          ></iframe>
          {/* <GoogleMapReact
                    defaultCenter={options.center}
                    defaultZoom={options.zoom}
                  >
                    <AnyReactComponent
                      lat={21.2334}
                      lng={72.8636}
                      text="i2i Infotech"
                    />
                  </GoogleMapReact> */}
        </div>
      </div>
      <section className="contact_us_page" id="contact_us_page_id">
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="title text-center">
                    <h3>Contact Information</h3>
                    <p>
                      We would love to answer your queries or doubts regarding
                      our services or request a quote. Let’s talk.
                    </p>
                  </div>
                  {/* title */}
                </div>
                <div className="w-100"></div>
                <div className="row contact_grid">
                  <div className="col-md-4 col-xs-12">
                    <div className="single_contact_info h-100">
                      <div className="icon">
                        <i>
                          <FaMapMarker />
                        </i>
                      </div>
                      <h4>Our Location</h4>
                      <p>
                        5<sup>th</sup> Floor, Office No. 511, Kyros Business Center,
                        Near navjivan hotel, Jakatnaka,
                        Surat, Gujarat, 395006
                      </p>
                    </div>
                    {/* single_contact_info */}
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="single_contact_info h-100">
                      <div className="icon">
                        <i>
                          <FaPhoneAlt />
                        </i>
                      </div>
                      <h4>Call Us Now</h4>
                      <p>+91 9427894598</p>
                    </div>
                    {/* single_contact_info */}
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="single_contact_info h-100">
                      <div className="icon">
                        <i>
                          <FaEnvelope />
                        </i>
                      </div>
                      <h4>Write Us Now</h4>
                      <p>contactus@sct.technology</p>
                      {/* <p>supportration@sct.technology</p> */}
                    </div>
                    {/* single_contact_info */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container */}
      </section>
      {/* contact page end*/}
    </>
  );
};

export default ContactMain;
