import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import { BannerOne } from "../../../resources/options/images";
import ModalVideo from "react-modal-video";
import { useState } from "react";
import Typical from "react-typical";

const Banner = () => {
  const [isOpen, SetOpen] = useState(false);

  const openModalProcess = () => {
    SetOpen(true);
  };

  return (
    <>
      {/* Banner section start*/}
      <section className="hero_banner" id="home_id">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="hero_inside_text">
                <h4>
                  A Top Rated IT Solution for the development of Web Application And AI Solution
                </h4>
                <h1>We turn your ideas into Reality</h1>
                <h5>Boost your business innovation with IT services</h5>
                <h4>
                  |{" "}
                  <span>
                    <Typical
                      steps={[
                        "Artificial intelligence ",
                        1000,
                        "Web Development",
                        1000,
                      ]}
                      loop={Infinity}
                      wrapper="span"
                    />
                  </span>
                </h4>
                {/* <p>
                  Lorem ipsum dolor sit amet consectetur sadipcing elitr,
                  consetetur sadipscing elitr, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat sed dilam voluptua.  
                </p> */}
                <div className="hero-btn">
                  <a href="/contact" className="btn text-uppercase">
                    Make your dream a Reality
                  </a>
                </div>
                {/* <div className="hero-btn">
                  <a href="/" className="btn">
                    Create Account
                  </a>
                  <button
                    className="btn-play video-btn"
                    onClick={openModalProcess}
                  >
                    <i>
                      <FaPlay className="play-icon" />
                    </i>
                    <b>
                      Play Video
                      <FaAngleDoubleRight className="top-right-icon" />
                    </b>
                  </button>

                
                  {isOpen ? (
                    <ModalVideo
                      channel="youtube"
                      isOpen={isOpen}
                      videoId="p5F_r_rFUQM"
                      onClose={() => SetOpen(false)}
                    />
                  ) : null}

                  
                </div> */}
              </div>
              {/* hero_inside_text */}
            </div>
            <div className="col-md-5">
              <div className="banner_dashboard_right">
                <img src={BannerOne} alt="" />
              </div>
              {/* banner_dashboard_right */}
            </div>
          </div>
        </div>
        {/* container */}
      </section>
      {/* Banner section end*/}
    </>
  );
};

export default Banner;
