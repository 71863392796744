import Breadcrumb from "../components/common/Breadcrumb";
import PageHelmet from "../components/common/Helmet";
import Footer from "../components/footer/Footer";
import HeaderMain from "../components/header/HeaderMain";
import CallToAction from "../components/home/action/CallToAction";
import SerivceAll from "../components/home/services/ServiceAll";
import TestimonialTwo from "../components/home/testimonials/TestimonialTwo";

const Services = () => {
  return (
    <>
      {/* Page title area start  */}
      <PageHelmet pageTitle="Services" />
      {/* Page title area end  */}

      {/* Header area start  */}
      {/* <HeaderMain logo="dark" styleSelect="dark_color" /> */}
      {/* Header area end  */}

      {/* Breadcrumb area start  */}
      <Breadcrumb pathName="Services" />
      {/* Breadcrumb area end  */}

      {/* Service area start  */}
      <SerivceAll />
      {/* Service area end  */}

      {/* CallToAction area start  */}
      <CallToAction styleSelect="home2" />
      {/* CallToAction area end  */}

      {/* Testimonial area start  */}
      <TestimonialTwo />
      {/* Testimonial area end  */}

      {/* Footer area start  */}
      {/* <Footer styleSelect="home2" interestedTrue /> */}
      {/* Footer area end  */}
    </>
  );
};

export default Services;
