const SingalCareerAll = ({ item }) => {

  return (
    <>

      <div
        className={`single_case_page`}
      >
        <div className="price-table highlighted">
          <div className="table-inner text-center">
            <div className="img-container">
              <img src={item.image} alt="#" />
            </div>
            <h4 >{item.title}</h4>
            <h5>(Openings: {item.opning}) </h5>
            <h6>{item.exp}</h6>
            <div class="flexible"></div>
            <a href="/applyforjob" className="btn btn-blue" style={{background:item.background}}>
              Apply Now 
            </a>
          </div>
        </div>
      </div>
      {/* singlecaseall end*/}
    </>
  );
};

export default SingalCareerAll;
