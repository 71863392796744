import { useState } from "react";
import { Link } from "react-router-dom";
import SingleService from "../../../elements/service/SingleService";
import {
  service1,
  service1bg,
  service2,
  service2bg,
  service2bg_hv,
  service3,
  service3bg,
  service3bg_hv,
  service4,
  service4bg,
  service4bg_hv,
} from "../../../resources/options/images";
import Shape from "./partial/Shape";

const Service = () => {
  const [serviceListOne] = useState([
    {
      title: "Software Development",
      imgList: {
        img: service1,
        bg: service1bg,
        hover: service3bg_hv,
      },
      description:
        "We provide a complete and accomplished bootstrap,  blockchain-based software development service, with a track record success spanning four years. Our initiative is to help blockchain and crypto start-ups create innovative  software solutions that impact the world, for the better.",
      duration: 1000,
      alt: "Software Development",
      style: "bg1",
    },
    {
      title: "UI/UX Design",
      imgList: {
        img: service2,
        bg: service2bg,
        hover: service2bg_hv,
      },
      description:
        "Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.",
      duration: 3000,
      alt: "UI/UX Design",
      style: "bg2",
    },
  ]);

  const [serviceListTwo] = useState([
    {
      title: "AI Development",
      imgList: {
        img: service3,
        bg: service3bg,
        hover: service3bg_hv,
      },
      description:
        "Our AI Development service provides cutting-edge solutions tailored to your business needs. Harnessing the latest advancements in artificial intelligence, we offer customized AI models, seamless integration, and continuous support. Elevate your operations, drive innovation, and achieve transformative results with our expert AI development team.",
      duration: 2000,
      alt: "AI Development",
      style: "bg3",
    },
    {
      title: "Web Development",
      imgList: {
        img: service4,
        bg: service4bg,
        hover: service4bg_hv,
      },
      description:
        "Our web applications team are experts in the field, providing superior web solutions. We create web applications which are tailored to your business needs and market requirements. Focus is on ease of use and in maintaining visitor experience.",
      duration: 4000,
      alt: "Web Development",
      style: "bg4",
    },
  ]);

  return (
    <>
      {/* service provide start*/}
      <section className="service_provide_sec" id="service_provide_sec_id">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="row h-100">
                <div className="col-md-12 align-self-center">
                  <div className="service_title title">
                    <h3>Services We're Providing</h3>
                    <p>
                      With our services, you will get quality, elegance, and the
                      latest technologies
                    </p>

                    <Link to="/services" className="btn text-uppercase">
                      explore our services
                    </Link>
                  </div>
                  {/* title */}
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row">
                {/* <div className="col-md-6 mrtop-1"> */}
                <div className="col-md-6 mrtop-1">
                  {serviceListTwo.map((item, index) => (
                    <SingleService item={item} key={index} />
                  ))}
                </div>
                <div className="col-md-6 ">
                  {serviceListOne.map((item, index) => (
                    <SingleService item={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container */}
        <Shape />
      </section>
      {/* service provide end*/}
    </>
  );
};

export default Service;
