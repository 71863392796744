const Breadcrumb = ({ pathName,breadcrumb  }) => {
  return (
    <>
      {/* broadcramp start */}
      <header className="inner_broadcramp">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="broadcramp_inside_hdr">
                <h4>{pathName}</h4>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>&nbsp;/&nbsp;</li>
                  <li> {breadcrumb ? breadcrumb : pathName}</li>
                </ul>
              </div>
              {/* broadcramp_inside_hdr */}
            </div>
          </div>
        </div>
        {/* container */}
      </header>
      {/* broadcramp end*/}
    </>
  );
};

export default Breadcrumb;
