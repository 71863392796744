import React from "react";
import PropTypes from "prop-types";
import {
  year_exper,
  upwork_Logo,
  project_acc,
  growth,
  expert_team,
} from "../../../resources/options/images";
function OurValues(props) {
  return (
    <>
      <section className="features" id="features_id">
        <div className="container">
          <div
            className="row justify-content-center align-items-center"
            id="lazzy2"
          >
            <div className="col-xl-24 col-lg-3 col-md-3 col-6 mt-4 b-r-1 p0">
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <img
                  src={upwork_Logo}
                  className="img-fluid"
                  alt="SCT"
                  title="SCT"
                />
                <h3>
                  <i className="bx bxs-star"></i> Top Rated
                </h3>
              </div>
            </div>
            <div className="col-xl-24 col-lg-3 col-md-3 col-6 mt-4 b-r-1 p0">
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <img
                  src={year_exper}
                  className="img-fluid"
                  alt="SCT"
                  title="SCT"
                />
                <h3>Years Of Experience</h3>
              </div>
            </div>
            <div className="col-xl-24 col-lg-3 col-md-3 col-6 mt-4 b-r-1 b-r-unset b-r-unsets p0">
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <img
                  src={growth}
                  className="img-fluid"
                  alt="SCT"
                  title="SCT"
                />
                <h3>Growth Rate</h3>
              </div>
            </div>
            <div className="col-xl-24 col-lg-3 col-md-3 col-6 mt-4  p0">
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <img
                  src={project_acc}
                  className="img-fluid"
                  alt="SCT"
                  title="SCT"
                />
                <h3>Projects Accomplished</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

OurValues.propTypes = {};

export default OurValues;
