import React, { useEffect, useState, Suspense } from "react";
import ReactPaginate from "react-paginate";

const SinlgeCaseAll = React.lazy(() =>
  import("../../elements/casestudie/SingleCaseAll")
);

const PaginatedItems = ({ itemsPerPage }) => {
  const [WorkList, setWorkList] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(WorkList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(WorkList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  useEffect(() => {
    // console.log("useEffect");
    fetch("/data/protfoilio.json")
      .then((res) => res.json())
      .then(
        (result) => {
          setWorkList(result);
          // console.log(result);
        },
        (error) => {
          console.error(error);
        }
      );
  }, []);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % WorkList.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setCurrentItems(WorkList);
  }, [WorkList]);
  return (
    <>
      <Suspense
        fallback={
          <div className="text-center">
            <div className="lds-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        }
      >
        <SinlgeCaseAll currentItems={currentItems} />

        <div className="py-3">
          <ReactPaginate
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
            className="pagination justify-content-center"
          />
        </div>
      </Suspense>
    </>
  );
};

export default PaginatedItems;
