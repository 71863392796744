const AboutUs = () => {
  return (
    <>
      {/* about-us start  */}
      <section className="about_content_iner" id="about_content_iner_id">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="content_text_about">
                <h4>Our History</h4>
                <p>
                  Our vision is to help you with innovation and technology and
                  give you the power to change the world with your ideas. The
                  desire to reach the sky solely through the joy of consumers
                  burns within us all the time.
                </p>
              </div>
              {/* content_text_about */}
            </div>
            <div className="col-md-6">
              <div className="content_text_about">
                <h4>Our Mission</h4>
                <p>
                  We are here to solve problems and help your business grow.
                  Many things can hold a business back, such as repetitive
                  tasks, disconnected systems, and inefficient manual processes.
                  This waste can cost your business valuable resources and
                  prevent you from reaching new customers, or worse, risk losing
                  existing ones. Our goal is to give you a solution to these
                  problems and do it on time and on budget.
                </p>
              </div>
              {/* content_text_about */}
            </div>
          </div>
        </div>
        {/* container */}
      </section>
      {/* about-us end */}
    </>
  );
};

export default AboutUs;
