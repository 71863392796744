import { useState, useEffect } from "react";
import * as baseApi from "../../../api/base";
import { useRef } from "react";
import loading from "../../../assets/img/loading.svg";
const ContactForm = () => {
  const imageRef = useRef();

  const [load, setLoad] = useState(false);

  const [experienceList] = useState([
    "Fresher",
    "1 Year +",
    "2 Year +",
    "3 Year +",
  ]);
  const [positionList] = useState([
    "Fresher",
    "Junior",
    "Senior",
    "Team Leader",
  ]);
  const [qualificationList] = useState([
    "12 Pass",
    "Graduate",
    "Master",
    // "Other",
  ]);
  const [fieldList, SetFieldList] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    message: "",
    location: "",
    profile: "",
    experience: "",
    position: "",
    qualification: "",
    file: null,
    _applyjob: true,
  });
  const clearFormData = () => {
    SetFieldList({
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      message: "",
      location: "",
      profile: "",
      experience: "",
      position: "",
      qualification: "",
      file: null,
      _applyjob: true,
    });
    imageRef.current.value = null;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      var formData = new FormData();
      formData.append("image", fieldList.file, imageRef.current.value);
      for (const prop in fieldList) {
        // console.log(`obj.${prop} = ${fieldList[prop]}`);
        formData.append(prop, fieldList[prop]);
      }

      setLoad(true);
      const response = await baseApi.addApplyJobData(formData);
      console.log(response.data);
      const data = response.data;
      if (data.status === 202) {
        alert("Application submitted Successfuly!");
        clearFormData();
        setLoad(false);
      } else {
        alert("Somthing went wrong");
        setLoad(false);
      }
      // alert("Submited Successfully!");
    } catch (error) {
      setLoad(false);
      console.error(error);
    }
  }

  return (
    <>
      {/* contact_form_page start*/}
      <section className="contact_form_page" id="contact_form_page_id">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="title text-center">
                <h3>Interested in this job? Apply from here.</h3>
                {/* <p>Apply Now</p> */}
              </div>
              {/* title */}
            </div>
            <div className="w-100"></div>
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      target="firstname"
                      value={fieldList.firstname}
                      onChange={(e) => {
                        SetFieldList({
                          ...fieldList,
                          firstname: e.target.value,
                        });
                      }}
                      placeholder="First Name*"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      target="lastname"
                      value={fieldList.lastname}
                      onChange={(e) => {
                        SetFieldList({
                          ...fieldList,
                          lastname: e.target.value,
                        });
                      }}
                      placeholder="Last Name*"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      target="email"
                      value={fieldList.email}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, email: e.target.value });
                      }}
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      target="mobile"
                      value={fieldList.mobile}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, mobile: e.target.value });
                      }}
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <select
                      className="input"
                      target="experience"
                      value={fieldList.experience}
                      onChange={(e) => {
                        SetFieldList({
                          ...fieldList,
                          experience: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Experience</option>
                      {experienceList.map((item) => (
                        <option
                          value={item}
                          selected={fieldList.experience === item}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <select
                      className="input"
                      target="position"
                      value={fieldList.position}
                      onChange={(e) => {
                        SetFieldList({
                          ...fieldList,
                          position: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Position</option>
                      {positionList.map((item) => (
                        <option
                          value={item}
                          selected={fieldList.position === item}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <select
                      className="input"
                      target="qualification"
                      value={fieldList.qualification}
                      onChange={(e) => {
                        SetFieldList({
                          ...fieldList,
                          qualification: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select Qualification</option>
                      {qualificationList.map((item) => (
                        <option
                          value={item}
                          selected={fieldList.qualification === item}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      target="location"
                      value={fieldList.location}
                      onChange={(e) => {
                        SetFieldList({
                          ...fieldList,
                          location: e.target.value,
                        });
                      }}
                      placeholder="Current Location*"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      target="profile"
                      value={fieldList.profile}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, profile: e.target.value });
                      }}
                      placeholder="Linkedln Profile"
                    />
                  </div>

                  <div className="col-12">
                    <input
                      type="file"
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, file: e.target.files[0] });
                      }}
                      placeholder="Attach Resume"
                      accept=".doc,.pdf"
                      ref={imageRef}
                      required
                    />

                    <p className=" text-muted text-end text-right align-self-end">
                      Allowed format: DOC & PDF
                    </p>
                  </div>

                  <div className="col-md-12">
                    <textarea
                      rows="4"
                      value={fieldList.message}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, message: e.target.value });
                      }}
                      placeholder="Say about your self"
                    ></textarea>

                    <button type="submit" className="btn" disabled={load}>
                      {load ? "Uploading..." : "Submit Now"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* container */}
      </section>
      {/* contact_form_page  end*/}
    </>
  );
};

export default ContactForm;
