import Breadcrumb from "../components/common/Breadcrumb";
import PageHelmet from "../components/common/Helmet";
import ApplyForJobForm from "../components/home/applyforjob/ApplyForJobForm";

const Contact = () => {
  return (
    <>
      {/* Page title area start  */}
      <PageHelmet pageTitle="Apply for Job" />
      {/* Page title area start  */}

      {/* Header area start  */}
      {/* <HeaderMain logo="dark" styleSelect="dark_color" /> */}
      {/* Header area end  */}

      {/* Breadcrumb area start  */}
      <Breadcrumb pathName="Apply for Job" />
      {/* Breadcrumb area end  */}

      {/* ApplyForJobMain area start  */}
      {/* <ApplyForJobMain /> */}
      {/* ApplyForJobMain area end  */}

      {/* ApplyForJobForm area start  */}
      <ApplyForJobForm />
      {/* ApplyForJobForm area end  */}

      {/* Footer area start  */}
      {/* <Footer styleSelect="home2" interestedTrue /> */}
      {/* Footer area end  */}
    </>
  );
};

export default Contact;
