import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  darkLogo,
  whiteLogo,
  mobileLogo,
} from "../../resources/options/images";
import OffCanvasSection from "./offCanvas/OffCanvasSection";

const HeaderMain = ({ logo, styleSelect }) => {
  const [showScroll, setShowScroll] = useState(false);
  const [mobileMenu, SetMobileMenu] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  const mobileMenuToggle = () => {
    SetMobileMenu(!mobileMenu);
  };

  const checkScrollTop = useCallback(() => {
    if (window.scrollY > 0) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [checkScrollTop]);

  const stickyLogo = <img src={darkLogo} alt="logo" />;

  const onScrollHide = useCallback(() => {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (path !== "/connect") {
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("mainheader").style.top = "0";
        } else {
          document.getElementById("mainheader").style.top = "-90px";
        }
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);
  useEffect(() => {
    window.addEventListener("load", onScrollHide);
    return () => window.removeEventListener("load", onScrollHide);
  }, [onScrollHide]);

  let logoUrl;
  if (logo === "dark") {
    logoUrl = <img src={darkLogo} alt="logo" />;
  } else {
    logoUrl = <img src={whiteLogo} alt="logo" />;
  }

  return (
    <>
      {path === "/connect" ? (
        <></>
      ) : (
        <>
          <header
            className={`header_part classic_header ${
              showScroll ? "menu_fixed" : ""
            } ${styleSelect} `}
            id="mainheader"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 ">
                  <nav className="navbar navbar-expand-xl justify-content-between align-items-center">
                    <div className="pu_logo_area">
                      <Link to="/" className="navbar-brand main_logo">
                        {stickyLogo}
                      </Link>
                      <a className="navbar-brand sticky_logo" href="/">
                        {stickyLogo}
                      </a>
                    </div>
                    <div className="main_nav_wrapper d-flex justify-content-end">
                      <div
                        className="main_nav collapse navbar-collapse justify-content-end"
                        id="navbarNavDropdown"
                      >
                        <ul className="navbar-nav">
                          <li className="nav-item dropdown">
                            <Link
                              to="/"
                              className="nav-link dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Home
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/about" className="nav-link">
                              About Us
                            </Link>
                          </li>
                          {/*  */}
                          <li className="nav-item dropdown">
                            <Link
                              to="/services"
                              className="nav-link dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Services
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/portfolio"
                              className="nav-link"
                              aria-haspopup="false"
                              aria-expanded="false"
                            >
                              Portfolio
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to="/career"
                              className="nav-link"
                              aria-haspopup="false"
                              aria-expanded="false"
                            >
                              Career
                            </Link>
                          </li>
                          {/* <li className="nav-item ">
                            <a
                              href="https://blog.sct.technology/"
                              className="nav-link"
                            >
                              Blog
                            </a> */}
                            {/* <Link to="/blog">Blogs</Link> */}
                          {/* </li> */}

                          <li className="nav-item">
                            <Link to="/contact" className="nav-link">
                              Contact
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="pu_collaps_menu_icon offcanvus_menu_trigger navbar-toggler collapsed mr-0"
                        onClick={mobileMenuToggle}
                      >
                        <div className="burger_icon">
                          <span className="burger_icon_top"></span>
                          <span className="burger_icon_bottom"></span>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </header>
          {/* header part end */}
          <section className={` ${mobileMenu ? "active_off_canvus" : ""}`}>
            <OffCanvasSection
              darkLogo={mobileLogo}
              mobileMenuToggle={mobileMenuToggle}
              mobileMenu={mobileMenu}
            />
          </section>
        </>
      )}
      {/* header part start*/}
    </>
  );
};

HeaderMain.defaultProps = {
  logo: "dark",
  style: "dark_color",
};

export default HeaderMain;
