import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import axios from "axios";
import { Lightbox } from "react-modal-image";
import { LazyImage } from "../../common/LazyImage";

function PortFolioDetails() {
  const badges = [
    "bg-primary",
    "bg-secondary",
    "bg-success",
    "bg-info",
    "bg-dark",
    "badge-light",
  ];
  function random(mn, mx) {
    return Math.random() * (mx - mn) + mn;
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    className: "",
    autoplay: true,
    adaptiveHeight: true,
  };
  const [projectId, setProjectID] = useState();
  const [projectData, setProjectData] = useState();
  const [getData, setGetData] = useState();
  const [currentImage, setCurrentImage] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    try {
      axios.get("/data/protfoilio.json").then((response) => {
        setGetData(response);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setProjectID(parseInt(id));
  }, [id]);

  useEffect(() => {
    setProjectData(
      getData?.data?.filter((project) => project.id === projectId)
    );
  }, [getData, projectId]);

  const openImageViewer = useCallback((images) => {
    // console.log("images", images);
    setCurrentImage(images);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <Breadcrumb
        pathName="OUR LATEST WORK IN DIFFERENT TECHNOLOGIES"
        breadcrumb="portfolio"
      />
      <div
        className={`container ${isViewerOpen === true ? "overflow-y" : ""}`}
        id="portfolioDetails_id"
      >
        {projectData?.map((item) => {
          const { img_list } = item;
          return (
            <Fragment key={item?.id}>
              <div className="row d-flex my-5">
                <div className="col-12 col-md-6">
                  <div className="py-2">
                    <div className="mb-0 ms-1">
                      <span className="h2">{item.name}</span>
                    </div>
                    {item.category &&
                      item.category.split(",").map((c, i) => {
                        return (
                          <span
                            key={i}
                            className={`m-1 p-2 badge-pill badge text-muted ${badges[5]}`}
                          >
                            {c}
                          </span>
                        );
                      })}
                    <div className="link fs-5 ms-1 mt-2">
                      {item?.link && item.link.length > 0 && (
                        <>
                          <a
                            href={item?.link}
                            target="_blank"
                            rel="noreferrer"
                            className="view_project"
                          >
                            View Link
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="py-2">
                    {item.description && (
                      <>
                        <div className="mb-2">
                          <span className="h5 text-muted">Description</span>
                        </div>
                        <p className="f-size">{item.description}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                {img_list &&
                  img_list.length > 0 &&
                  img_list.map((imgs, index) => {
                    return (
                      <Fragment key={`img-${index}`}>
                        <div
                          className="col-lg-4 col-md-6 d-flex align-items-center justify-content-center"
                          onClick={() => openImageViewer(`../../../${imgs}`)}
                        >
                          <div className="d-block mt-7">
                            <LazyImage
                              className="rounded img-fluid"
                              src={`../../../${imgs}`}
                              alt="imag1"
                            />
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
              {isViewerOpen && (
                <Lightbox
                  medium={currentImage}
                  hideDownload={true}
                  hideZoom={true}
                  large={currentImage}
                  alt={item?.name || "Portfolio"}
                  onClose={closeImageViewer}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
}

export default PortFolioDetails;

/**../../../../public */
