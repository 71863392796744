import {
  shapeimg3,
  shapeimg4,
  shapeimg5,
  shapeimg6,
} from "../../../../resources/options/images";

const ShapeTwo = () => {
  return (
    <>
      <img src={shapeimg3} alt="shape three" className="shape-three" />
      <img src={shapeimg4} alt="shape four" className="shape-four" />
      <img src={shapeimg5} alt="shape five" className="shape-five" />
      <img src={shapeimg6} alt="shape six" className="shape-six" />
    </>
  );
};

export default ShapeTwo;
