import { useState } from "react";
import SingalCareerAll from "../../../elements/career/SingalCareerAll";
import {
  an_2,
  io_2,
  flutter_2,
  php_2,
  native,
  ux_ui,
  nodejs,
  vuejs,
  wordpress,
} from "../../../resources/options/images";
import Pagination from "../../common/Pagination";

const CareerAll = () => {
  const [careerList] = useState([
    {
      title: "React Developer",
      image: native,
      exp: "0 to 5 Year",
      opning: "5",
      background: "#61DBFB",
    },
    {
      title: "Node JS Developer",
      image: nodejs,
      exp: "6 month to 2 Year",
      opning: "2",
      background: "#68A063",
    },

    {
      title: "Php/Laravel Developer",
      image: php_2,
      exp: "2 to 5 Year",
      opning: "2",
      background: "#474A8A",
    },
    {
      title: "Vue JS Developer",
      image: vuejs,
      exp: "6 Month to 2 Year",
      opning: "4",
      background: "#41B883",
    },
   
  ]);

  return (
    <>
      {/* case studies start*/}
      <section className="case_studies_page" id="case_studies_page_id">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="title text-center">
                <h3>Current Openings</h3>
                <div className="heading-decoration">
                  <span className="first"></span>
                  <span className="second"></span>
                </div>
              </div>
            </div>
            <div className="career_tables row">
              {careerList.map((item, index) => (
                <div
                  className="col-lg-3 col-md-4 col-sm-6"
                  key={index}
                  data-aos="fade-left"
                >
                  <SingalCareerAll item={item} />
                </div>
              ))}
            </div>

            <div className="w-100 my-3"></div>
          </div>
        </div>
        {/* container */}
      </section>
      {/* case studies end*/}
    </>
  );
};

export default CareerAll;
