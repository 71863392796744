import client from "../client.js";
var config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    crossDomain: true,
    // mode: "no-cors",
  },
};

const optionsMultiple = {
  headers: {
    // "Content-Type": "application/json",
    // "Content-type": "multipart/form-data",
    crossDomain: true,
    "Access-Control-Allow-Origin": "*",
  },
};
// export const getAllInstitutionsApi = () => client.get("/v1/admin/institutions");

// export const getDepartmentsApi = (departmentId) =>
//   client.get(
//     `/v1/admin/departments/institutions?institutionId=${departmentId}`
//   );
//export const getStats = () => client.get("/v1/getStats");

export const getApplyJobData = () => client.get("/");
export const addApplyJobData = (payload) =>
  client.post("/index.php", payload, optionsMultiple);
export const contactusForm = (payload) =>
  client.post("/contactus.php", payload, optionsMultiple);
export const subscribeus = (payload) =>
  client.post("/subscribeus.php", payload, optionsMultiple);
