import { useState, useEffect } from "react";
import * as baseApi from "../../../api/base";
const ContactForm = () => {
  const [fieldList, SetFieldList] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const clearFormData = () => {
    SetFieldList({
      name: "",
      email: "",
      mobile: "",
      message: "",
    });
  };
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      var formData = new FormData();
      for (const prop in fieldList) {
        formData.append(prop, fieldList[prop]);
      }
      const response = await baseApi.contactusForm(formData);
      const data = response.data;
      if (data.status === 202) {
        alert("Contact submitted Successfuly!");
        clearFormData();
      } else {
        alert("Somthing went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {/* contact_form_page start*/}
      <section className="contact_form_page" id="contact_form_page_form_id">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="title text-center">
                <h3>Get in touch with us</h3>
                <p>
                  Book your free call with our technical consultant now. You can
                  contact us with anything related to our services. We'll get in
                  touch with you as soon as possible.
                </p>
              </div>
              {/* title */}
            </div>
            <div className="w-100"></div>
            <div className="col-md-12">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      type="text"
                      value={fieldList.name}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, name: e.target.value });
                      }}
                      placeholder="Name *"
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="email"
                      value={fieldList.email}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, email: e.target.value });
                      }}
                      placeholder="Email *"
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      value={fieldList.mobile}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, mobile: e.target.value });
                      }}
                      placeholder="Phone Number *"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      rows="4"
                      value={fieldList.message}
                      onChange={(e) => {
                        SetFieldList({ ...fieldList, message: e.target.value });
                      }}
                      placeholder="Message *"
                      required
                    ></textarea>
                    <button type="submit" className="btn">
                      Submit Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* container */}
      </section>
      {/* contact_form_page  end*/}
    </>
  );
};

export default ContactForm;
