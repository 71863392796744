import Breadcrumb from "../components/common/Breadcrumb";
import PageHelmet from "../components/common/Helmet";
import CaseStudieAll from "../components/home/casestudies/CaseStudieAll";

const PortFolio = () => {
    return (
        <>
            {/* Page title area start  */}
            <PageHelmet pageTitle="Portfolio" />
            {/* Page title area end  */}

            {/* Header area start  */}
            {/* <HeaderMain logo="dark" styleSelect="dark_color" />/ */}
            {/* Header area end  */}

            {/* Breadcrumb area start  */}
            <Breadcrumb pathName="Portfolio" />
            {/* Breadcrumb area end  */}

            {/* CallToAction area start  */}
            <CaseStudieAll />
            {/* CallToAction area end  */}

            {/* Footer area start  */}
            {/* <Footer styleSelect="home2" interestedTrue /> */}
            {/* Footer area end  */}
        </>
    );
};

export default PortFolio;
