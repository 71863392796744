import "./index.scss";
import React from "react";
import PropTypes from "prop-types";
import ShapeTwo from "../services/partial/ShapeTwo";
import { ourTech } from "../../../utils/ourtech";
const OurTechnologies = (props) => {
  return (
    <section className={`ourTechnologies_section`} id="OurTechnologies_id">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="title text-center">
              <h3 className="heading_border_text">Technologies</h3>
              <h3>Our Technologies</h3>
            </div>
            {/* title */}
          </div>
        </div>
        <div
          className="p-0 row list_container"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          {ourTech.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className={`col-md-2 col-6 text-center   ${index % 2 === 0 && " "
                    }`}
                >
                  <div className="color_box">
                    <img
                      src={item.img}
                      alt=""
                      className={`color_box_img ${index % 2 === 0 ? "" : ""}`}
                      title={item.title}
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <ShapeTwo />
    </section>
  );
};

OurTechnologies.propTypes = {};

export default OurTechnologies;
