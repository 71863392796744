import { useState } from "react";
import { planImage } from "../../../resources/options/images";
import { FaAngleRight } from "react-icons/fa";
const Plan = () => {
  const [content] = useState({
    heading: "Power up your business with the best team",
    image: planImage,
    description: "",
    list: [
      {
        name: "We've been supporting Android and iOS development since the beginning.",
      },
      {
        name: "Perfect for large sites or agencies managing multiple clients.",
      },
      {
        name: "Our easy-to-use control panel and API let you spend.",
      },
    ],
  });

  return (
    <>
      {/* get plan start*/}
      <section className="get_plan_sec" id="get_plan_sec_id">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="plan_img">
                <img src={content.image} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row h-100">
                <div className="col-md-12 align-self-center">
                  <div className="plan_title title">
                    <h3>{content.heading}</h3>
                    <p>{content.description}</p>
                    <ul>
                      {content.list.map((item, index) => (
                        <li key={index}>
                          <FaAngleRight /> {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* container */}
      </section>
      {/* get plan end*/}
    </>
  );
};

export default Plan;
