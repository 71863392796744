import { Link } from "react-router-dom";
import { polygonVector, aboutVector } from "../../../resources/options/images";

const About = ({ styleSelect, readMore }) => {
  return (
    <>
      {/* about us start*/}
      <section className={`about_us_sec ${styleSelect}`} id="about_id">
        <div className="container">
          <div className="row">
            <div className="col-md-7 offset-md-5">
              <div className="about_content">
                <h3>About Us</h3>
                <p className="h4 font-weight-bold pb-0">
                  Vision, Values, and People: Our Greatest Treasures
                </p>
                <p>
                  At SCT, We transform ideas into reality. As a globally
                  recognized IT company, we pride ourselves on being more than
                  just a service provider; we are a family of skilled
                  professionals dedicated to crafting innovative solutions.
                  Whether partnering with global brands, enterprises, mid-size
                  businesses, or startups, our expertise and commitment drive
                  success across diverse industries.
                </p>
                {readMore ? (
                  <Link
                    to="/about"
                    className="btn btn-outline-primary text-uppercase"
                  >
                    Read More
                  </Link>
                ) : (
                  ""
                )}
              </div>
              {/* about_content */}
            </div>
          </div>
        </div>
        {/* container */}
        <div className="right_bg_about">
          <img src={polygonVector} className="one" alt="#" />
          <img src={aboutVector} className="two" alt="#" />
        </div>
      </section>
      {/* about us end */}
    </>
  );
};

export default About;
