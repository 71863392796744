import ShapeTwo from "../services/partial/ShapeTwo";

const CallToAction = ({ styleSelect }) => {
  return (
    <>
      {/* interested project start*/}
      <section className={`interested_pro ${styleSelect}`} id="CallToAction_id">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="title text-center">
                <h3>Interested! Have any dream projects?</h3>
                <p>Let's Get Started With Your Dream Project</p>
                <a href="/contact" className="btn">
                  Get A Quote
                </a>
              </div>
              {/* title */}
            </div>
          </div>
        </div>
        {/* container */}
        <ShapeTwo />
      </section>
      {/* interested project end*/}
    </>
  );
};

export default CallToAction;
