import { Link } from "react-router-dom";
import { polygonVector, aboutVector } from "../../../resources/options/images";

const Career = ({ styleSelect, readMore }) => {
  return (
    <>
      {/* about us start*/}
      <section className={`about_us_sec ${styleSelect}`} id="about_us_sec_id">
        <div className="container">
          <div className="row">
            <div className="col-md-7 offset-md-5">
              <div className="about_content">
                <h3>Find the most exciting startup jobs</h3>
                <p className="h4 font-weight-bold pb-0">
                  Join our Forward-Thinking Innovation
                </p>
                <p>
                  We are an experienced, forward-thinking software development
                  team shaping a new reality with exciting new ideas and vast
                  experience in developing and utilizing cutting edge
                  technologies. Join us and get new opportunities for career
                  growth.
                </p>
                {readMore ? (
                  <Link to="/about" className="btn">
                    Read More
                  </Link>
                ) : (
                  ""
                )}
              </div>
              {/* about_content */}
            </div>
          </div>
        </div>
        {/* container */}
        <div className="right_bg_about">
          <img src={polygonVector} className="one" alt="#" />
          <img src={aboutVector} className="two" alt="#" />
        </div>
      </section>
      {/* about us end */}
    </>
  );
};

export default Career;
