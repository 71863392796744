import { useState } from "react";
import SingleFeature from "../../../elements/feature/SingleFeature";

import {
  mission_customer,
  mission_hand,
  mission_transparency,
  mission_work,
} from "../../../resources/options/images";

const Feature = () => {
  const [featureList] = useState([
    {
      title: "100% Transparency",
      img: mission_transparency,
      description:
        "We maintain 100% transparency and precision to deliver every project without any defect.",
      duration: 1000,
      alt: "100% Transparency",
    },
    {
      title: "Customer Centric",
      img: mission_customer,
      description:
        "We adopt a customer centric approach to work according to customer's specific demands.",
      duration: 2000,
      alt: "Customer Centric",
    },
    {
      title: "Flexible Engagements",
      img: mission_hand,
      description:
        "We use an adaptive and flexible engagement policy to deliver every project to the client.",
      duration: 3000,
      alt: "Flexible Engagements",
    },
    {
      title: "Work Efficiency",
      img: mission_work,
      description:
        "We have been working with adequate efficiency on projects to deliver what our clients need.",
      duration: 3000,
      alt: "Work Efficiency",
    },
  ]);
  return (
    <>
      {/* measure business feature start*/}
      <section className="measure_business_sec" id="measure_business_sec_id">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="title text-center">
                <h3 className="heading_border_text">Mission</h3>
                <h3>Our Mission</h3>
                {/* <p>
                  We are here to solve problems and help your business grow.
                  Many things can hold a business back such as repetitive tasks,
                  disconnected systems, and inefficient manual processes. This
                  waste can cost your business valuable resources and prevent
                  you from reaching new customers or worse: risk losing existing
                  ones. Our goal is to give you a solution to these problems and
                  do it on time and on budget.
                </p> */}
              </div>
              {/* title */}
            </div>
            <div className="w-100"></div>

            {featureList.map((item, index) => (
              <div className="col-md-3" key={index}>
                <SingleFeature item={item} />
              </div>
            ))}
          </div>
        </div>
        {/* container */}
      </section>
      {/* measure business feature end */}
    </>
  );
};

export default Feature;
