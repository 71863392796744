import { useEffect } from "react";
import "./theme.scss";
import AOS from "aos";
import ScrollToTop from "./resources/ScrollToTop";

// Home layout
import HomeOne from "./home/HomeOne";

import About from "./blocks/About";
import Services from "./blocks/Services";
import Contact from "./blocks/Contact";
import Career from "./blocks/Career";
import Portfolio from "./blocks/Portfolio";
import Policy from "./policy";

import Error404 from "./blocks/Error404";
import ApplyForJob from "./blocks/ApplyForJob";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HeaderMain from "./components/header/HeaderMain";
import Footer from "./components/footer/Footer";
import PortFolioDetails from "./components/home/portfolio/portfolioDetails";
import Connect from "./components/connect/Connect";
import MyLeaderBoardAd from "./components/common/MyLeaderBoardAd";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Router>
        <HeaderMain logo="dark" styleSelect="dark_color" />

        <ScrollToTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={HomeOne}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio`}
              component={Portfolio}
            />

            {/* ApplyForJob */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/applyforjob`}
              component={ApplyForJob}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/services`}
              component={Services}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/career`}
              component={Career}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolioDetails/:id`}
              children={<PortFolioDetails />}
            ></Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/connect`}
              children={<Connect />}
            ></Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/privacy-policy`}
              component={Policy}
            />
            <Redirect exact from="/login" to="/email" />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/404`}
              component={Error404}
            />
            <Route component={Error404} />
          </Switch>
        </ScrollToTop>
        <MyLeaderBoardAd
          slot="8764604197"
          googleAdId="ca-pub-3048047986854497"
        />
        <Footer styleSelect="home2" />

        {/* <Footer styleSelect="home2" interestedTrue /> */}
      </Router>
    </>
  );
};

export default App;
