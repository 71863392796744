import PaginatedItems from "../../common/Pagination";
import "react-awesome-lightbox/build/style.css";
import Shape from "../services/partial/Shape";
import ShapeThree from "../services/partial/ShapeThree";
import ShapeTwo from "../services/partial/ShapeTwo";
// import Shape from "./partial/Shape";
const CaseStudieAll = () => {
  return (
    <>
      {/* case studies start*/}
      <section className="case_studies_page" id="portfolio_lists">
        <div className="container">
          <div className="row">
            {/* {workList.map((item, index) => (
              <div className="col-md-4" key={index}>
                // <SinlgeCaseAll item={item} />
              </div>
            ))} */}

            <div className="col-md-12">
              <PaginatedItems itemsPerPage={20} />
              {/* pagination-post */}
            </div>
          </div>
        </div>
        {/* container */}
        <Shape />
        {/* <ShapeThree /> */}
        <ShapeTwo />
      </section>
      {/* case studies end*/}
    </>
  );
};

export default CaseStudieAll;
