const SingleAllService = ({ item }) => {
  return (
    <>
      {/* single_service_page start */}
      <div className="single_service_page">
        <div className="icon">
          <img src={item.imgList.img} alt="" />
          <img src={item.imgList.bg} className="show" alt="" />
          <img src={item.imgList.hover} className="hide" alt="" />
        </div>
        <h4>
          <a href="/">{item.title}</a>
        </h4>
        <p>{item.description}</p>
      </div>
      {/* single_service_page end*/}
    </>
  );
};

export default SingleAllService;
