import Breadcrumb from "../components/common/Breadcrumb";
import PageHelmet from "../components/common/Helmet";
import ContactForm from "../components/home/contact/ContactForm";
import ContactMain from "../components/home/contact/ContactMain";

const Contact = () => {
  return (
    <>
      {/* Page title area start  */}
      <PageHelmet pageTitle="Contact" />
      {/* Page title area start  */}

      {/* Header area start  */}
      {/* <HeaderMain logo="dark" styleSelect="dark_color" /> */}
      {/* Header area end  */}

      {/* Breadcrumb area start  */}
      <Breadcrumb pathName="Contact us" />
      {/* Breadcrumb area end  */}

      {/* ContactMain area start  */}
      <ContactMain />
      {/* ContactMain area end  */}

      {/* ContactForm area start  */}
      <ContactForm />
      {/* ContactForm area end  */}

      {/* Footer area start  */}
      {/* <Footer styleSelect="home2" interestedTrue /> */}
      {/* Footer area end  */}
    </>
  );
};

export default Contact;
