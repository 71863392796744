/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import Logo from "../../assets/img/icon.svg";
import {
  FaFacebookF,
  FaSms,
  FaTwitter,
  FaInstagram,
  FaBehance,
  FaLinkedin,
  FaDribbble,
  FaPhoneAlt,
  FaWhatsapp,
  FaSkype,
} from "react-icons/fa";
import bg_big from "../../assets/img/connect/connect_bg_big.svg";
import bg_small from "../../assets/img/connect/connect_bg_small.svg";
import { IoIosMail } from "react-icons/io";
import { IoEarthSharp } from "react-icons/io5";

const Connect = () => {
  const [click, setClick] = useState(false);
  return (
    <>
      <div className="connect_main">
        <div
          className={`card connect_card shadow-md ${click ? "flipped" : ""}`}
        >
          <div className="corner"></div>
          <div className="corner"></div>
          <div className="corner"></div>
          <div className="corner"></div>
          <div className="logo_container img-fluid py-2">
            <div className="d-flex justify-content-between align-items-center my-2">
              <div className="img_container d-flex me-2">
                <img className="card-img-top" src={Logo} alt="Card image cap" />
              </div>
              <div className="text_sub">
                <h2>IDEA TO IMPLEMENTATION</h2>
                <h1>INFOTECH</h1>
              </div>
            </div>
            <p>Software Consultancy</p>
          </div>
          <div className="services_container my-2">
            <div className="row justify-content-between flex-wrap box_m">
              <div className="p my-2 text-start">Services</div>
              <div className="col-12 col-md-6">
                <ul>
                  <li>Web Development</li>
                  <li>Application Development</li>
                  <li>Software Development</li>
                  <li>UI / UX Design</li>
                </ul>
              </div>
              <div className="col-12 col-md-6">
                <ul>
                  <li>SEO Marketing</li>
                  <li>AR / VR Development</li>
                  <li>AI - ML App Development</li>
                  <li>IOT Solutions</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full card-body p-0 connect_card_body">
            <div className="ul">
              {/* sms and call */}
              <a
                href="tel:+919023607051"
                target="_blank"
                rel="noopener noreferrer"
                className="outside"
              >
                <div className="li my-2">
                  <div className="img call">
                    <FaPhoneAlt />
                  </div>

                  {/* sms://+919023607051 */}
                  {/* <a
                  href="sms://+919023607051"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <div className="img call">
                  <FaSms />
                  </div>
                </a> */}
                  <div className="text w-100 text-start align-self-center">
                    <span className="h5 m-0 p-0">Call Us</span>
                    <span className="h6 m-0 p-0 number"> (+91 9023607051)</span>
                  </div>
                </div>
              </a>

              {/* whatsapp */}
              <a
                href="https://wa.me/919023607051"
                target="_blank"
                rel="noopener noreferrer"
                className="outside"
              >
                <div className="li my-2">
                  <div className="img call">
                    <FaWhatsapp />
                  </div>

                  <div className="text w-100 text-start">
                    <span className="h5 m-0 p-0">Chat With Us</span>
                  </div>
                </div>
              </a>
              {/* email */}
              <a
                href="mailto:contactus@sct.technology"
                target="_blank"
                rel="noopener noreferrer"
                className="outside"
              >
                <div className="li my-2">
                  <div className="img call">
                    <IoIosMail />
                  </div>

                  <div className="text w-100 text-start">
                    <span className="h6 m-0 p-0">contactus@sct.technology</span>
                  </div>
                </div>
              </a>

              {/* website */}
              <a
                href="https://sct.technology"
                target="_blank"
                rel="noopener noreferrer"
                className="outside"
              >
                <div className="li my-2">
                  <div className="img call">
                    <IoEarthSharp />
                  </div>

                  <div className="text w-100 text-start">
                    <span className="h6 m-0 p-0">sct.technology</span>
                  </div>
                </div>
              </a>

              {/* skype */}

              <a
                href="skype:live:blukhi99?chat"
                target="_blank"
                rel="noopener noreferrer"
                className="outside"
              >
                <div className="li my-2">
                  <div className="img call">
                    <FaSkype />
                  </div>

                  <div className="text w-100 text-start">
                    <span className="h5 m-0 p-0">Skype Us</span>
                  </div>
                </div>
              </a>
            </div>

            <div className="social_container">
              <div className="d-flex justify-content-around align-items-center my-2">
                <div className="icon instagram">
                  <a href="#">
                    <FaInstagram />
                  </a>
                </div>
                <div className="icon facebook">
                  <a href="#">
                    <FaFacebookF />
                  </a>
                </div>
                <div className="icon twitter">
                  <a href="#">
                    <FaTwitter />
                  </a>
                </div>
                <div className="icon dribbble">
                  <a href="#">
                    <FaDribbble />
                  </a>
                </div>
                <div className="icon linkdin">
                  <a href="#">
                    <FaLinkedin />
                  </a>
                </div>
                <div className="icon behance">
                  <a href="#">
                    <FaBehance />
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="contact_btn d-flex mt-4">
              <div className="btn_message me-3">
                <button className="btn btn-primary">Message</button>
              </div>
              <div className="btn_follow">
                <button className="btn btn-primary">Follow</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Connect;
