import React from 'react';
import Slider from 'react-slick';
import NextArrowIcon from "../../../assets/img/next-svgrepo-com.svg";
import PrevArrowIcon from "../../../assets/img/previous-svgrepo-com.svg";
import "./testimonial.css"

const testimonials = [
  [
    "Parth is a brilliant developer. Very professional with great communication. His coding skills are astounding, he always went above and beyond the task, always encouraging and pushing the team to work hard and stick to deadlines. Ready to work for next with his and his team Idea to Implementation Infotech.",
    "Parth was the first person I hired on this platform that actually solved my NodeJS problem. He went above and beyond, and even solved problems in my code that weren't in the job description (he fixed a few problems in my frontend too) Would hire him again in a heartbeat.",
  ],
  [
    "It was really great to have him for my work done within deadline . He did his job out of the box and fix the problem when I wanted to do. He knows how to collaborate with others effectively to find solutions to problems. Hope we will work again.",
    "Its been pleasure to work with parth. he is very quick and available for the task. He completed all the tasks within time and definitely look forward to work with him again.",
  ],
];

const singleSlides = [
  "Parth is a brilliant developer. Very professional with great communication. His coding skills are astounding, he always went above and beyond the task, always encouraging and pushing the team to work hard and stick to deadlines. Ready to work for next with his and his team Idea to Implementation Infotech.",
  "It was really great to have him for my work done within deadline . He did his job out of the box and fix the problem when I wanted to do. He knows how to collaborate with others effectively to find solutions to problems. Hope we will work again.",
  "Parth was the first person I hired on this platform that actually solved my NodeJS problem. He went above and beyond, and even solved problems in my code that weren't in the job description (he fixed a few problems in my frontend too) Would hire him again in a heartbeat.",
  "Its been pleasure to work with parth. he is very quick and available for the task. He completed all the tasks within time and definitely look forward to work with him again.",
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={NextArrowIcon} alt="Next Arrow" className="arrow-icon" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={PrevArrowIcon} alt="Previous Arrow" className="arrow-icon rotate-180" />
    </div>
  );
}

const Testimonials = () => (
  <div className="bg_color_2">
    <div className="custom-container">
      <div className="text-center position-relative">
        <h2 className="heading_border_text heading_title_text">Client Testimonial</h2>
        <h2 className="heading_title_text">Client Testimonial</h2>
        <span className="section-header-line"></span>
      </div>

      {/* Desktop View */}
      <div className="carousel mt-5 d-none d-lg-block container">
        <Slider {...settings}>
          {testimonials.map((slide, slideIndex) => (
            <div key={slideIndex}>
              <div className="row carousel-height">
                {slide.map((testimonial, testimonialIndex) => (
                  <div className="col-lg-6 col-md-12 mb-3" key={testimonialIndex}>
                    <div className="card">
                      <div className="card-body testimonial">
                        <div className="testimonial-content">
                          <img className="double_quote_img" src="/assets/img/quote_blue.png" alt="double quote" />
                          {testimonial}
                        </div>
                      </div>
                      <span className="client_name"></span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Tablet & Mobile View */}
      <div className="carousel mt-5 d-block d-lg-none container">
        <Slider {...settings}>
          {singleSlides.map((testimonial, testimonialIndex) => (
            <div key={testimonialIndex}>
              <div className="row carousel-height">
                <div className="col-12 mb-3">
                  <div className="card">
                    <div className="card-body testimonial">
                      <div className="testimonial-content">
                        <img className="double_quote_img" src="/assets/img/quote_blue.png" alt="double quote" />
                        {testimonial}
                      </div>
                    </div>
                    <span className="client_name"></span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  </div>
);

export default Testimonials;
