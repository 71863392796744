const SingleFeature = ({ item }) => {
  return (
    <>
      {/* business_feature_single_in start*/}
      <div
        className={`business_single_in ${item?.style}`}
        data-aos="fade-up"
        data-aos-duration={item.duration}
      >
        <img src={item.img} alt={item.alt} />
        <h4>{item.title}</h4>
        <p>{item.description}</p>
      </div>
      {/* business_feature_single_in end*/}
    </>
  );
};

export default SingleFeature;
